/* eslint-disable react/display-name */
import { CloseOutlined } from '@ant-design/icons';
import { Drawer } from 'antd';
import { ReactNode } from 'react';
import useIsMobile from '../../hooks/isMobile';
import React, { useCallback, useMemo } from 'react';

export interface PulppoDrawerProps {
    visible: boolean;
    closable?: boolean;
    onClose?: () => void;
    children?: ReactNode;
    extra?: ReactNode;
    inline?: ReactNode;
    className?: string;
    title?: string | ReactNode;
    zIndex?: number;
    bodyStyle?: any;
    headerStyle?: any;
    push?: boolean;
    width?: number | string;
}

const MemoizedExtra = React.memo(
    ({ extra, closable, onClose }: { extra: ReactNode; closable: boolean; onClose: () => void }) => {
        if (!closable) return null;
        return (
            <div className="flex items-center">
                {extra}
                <CloseOutlined width={34} className="ml-2 rounded-none bg-black p-1 text-white" onClick={onClose} />
            </div>
        );
    }
);

const defaultBodyStyle = {};
const defaultHeaderStyle = {};

const DEFAULT_ZINDEX = 0;
const DEFAULT_WIDTH = 500;
const DEFAULT_TITLE = ' ';

export const PulppoDrawer = React.memo(
    ({
        visible,
        onClose,
        push = true,
        className = '',
        closable = true,
        inline = null,
        children = <></>,
        extra = <></>,
        width = DEFAULT_WIDTH,
        title = DEFAULT_TITLE,
        bodyStyle = defaultBodyStyle,
        zIndex = DEFAULT_ZINDEX,
        headerStyle = defaultHeaderStyle
    }: PulppoDrawerProps) => {
        const isMobile = useIsMobile();
        const handleClose = useCallback(() => {
            onClose && onClose();
        }, [onClose]);

        const drawerWidth = useMemo(() => (inline ? 2 * +width : width), [inline, width]);
        const drawerPlacement = useMemo(() => (isMobile ? 'bottom' : 'right'), [isMobile]);

        return (
            <Drawer
                push={push}
                zIndex={zIndex}
                placement={drawerPlacement}
                onClose={handleClose}
                className={`overscroll-contain ${className}`}
                title={title ? <p className="text-base font-medium">{title}</p> : null}
                width={drawerWidth}
                closable={false}
                height={'100%'}
                autoFocus={false}
                headerStyle={headerStyle}
                destroyOnClose
                extra={closable ? <MemoizedExtra extra={extra} closable={closable} onClose={handleClose} /> : null}
                open={visible}
                bodyStyle={bodyStyle}
            >
                {inline && (
                    <div className="flex h-full">
                        <div className="h-full flex-1 p-4">{inline}</div>
                        <div className="h-full flex-1">{children}</div>
                    </div>
                )}
                {!inline && children}
            </Drawer>
        );
    }
);
